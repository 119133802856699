import React from 'react';
// import PropTypes from 'prop-types';

function PersonRow(props) {
  const { name, title, photo, children } = props;
  return (
    <div className="row js as wrap readable mt32 mb32 wrap">
      <img
        src={photo}
        style={{
          width: 'calc(5vw + 24px)',
          height: 'calc(5vw + 24px)',
          borderRadius: 'calc(5vw + 12px)',
          boxShadow: '0px 3px 6px #00000080',
          margin: '0px 2vw 2vw 0px'
        }}
        alt={name + ' profile photo'}
      />
      <div className="col js as fl1">
        <h6 className="bl80">{name}</h6>
        <p className="small bl60 mb8">{title}</p>
        <p className="bl70" style={{ minWidth: '250px' }}>
          {children}
        </p>
      </div>
    </div>
  );
}

PersonRow.propTypes = {};

export default PersonRow;
