import React from 'react';
import { Icon, Popover } from 'antd';

const PricingSection = () => {
  return (
    <div className="section">
      <a id="pricing" key="anchor" href="." style={{ visibility: 'hidden', cursor: 'none' }}>
        Pricing
      </a>
      <div className="wide col ac sectioncontent">
        <h2 className="mb32">Pricing</h2>
        <div className="row ac pricingrow">
          <div className="col jc ac wrap pricingrowitem">
            <h3 style={{ fontSize: '100pt', lineHeight: 1 }}>$1</h3>
            <p>
              per active patient{' '}
              <Popover
                placement="bottom"
                title='What is an "active patient"?'
                content={
                  <div style={{ maxWidth: '250px' }}>
                    Our definition of "active patient" is simple. If a given patient pays{' '}
                    <em>you</em> within a particular billing cycle (whether as part of a recurring
                    membership or a one-off charge), then they're active. If you don't get paid, we
                    don't get paid. If a patient's membership lapses, we stop charging you
                    automatically.
                  </div>
                }>
                <Icon type="info-circle" style={{ fontSize: '10pt' }} />
              </Popover>
            </p>
            <p>per month</p>
          </div>
          <h5 style={{ margin: '20px 100px' }}>+</h5>
          <div className="col jc ac wrap pricingrowitem">
            <p style={{ fontSize: '16pt' }}>2.9% + 30¢</p>
            <p style={{ opacity: 0.65 }}>credit card fees</p>
            <p style={{ fontSize: '16pt', marginTop: '20px' }}>$0.25</p>
            <p style={{ opacity: 0.65 }}>ACH fees</p>
            <br />
            <p style={{ opacity: 0.7, fontSize: '9pt', textAlign: 'center' }}>
              * These fees are levied by our payments processor, Stripe. We do not mark up these
              fees for additional profit.
            </p>
          </div>
        </div>
        <div className="row jc as wrap pricingdetails">
          <p>
            <Icon type="check" />
            All inclusive
          </p>
          <p>
            <Icon type="check" />
            Free until you open your doors
          </p>
          <p>
            <Icon type="check" />
            Startup-friendly
          </p>
          <p>
            <Icon type="check" />
            No minimum monthly spend
          </p>
          <p>
            <Icon type="check" />
            Unlimited providers
          </p>
          <p>
            <Icon type="check" />
            Unlimited locations
          </p>
          <p>
            <Icon type="check" />
            No setup fees
          </p>

          <p>
            <Icon type="check" />
            No eRx fees
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
