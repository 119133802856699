import React from 'react';
import { Icon } from 'antd';
import WaitlistInput from './WaitlistInput';

export default () => [
  <div
  key="cta"
    className="section"
    style={{
      // backgroundColor: '#000000e0',
      backgroundImage: 'url("/img/bagel_circle_center_crop.jpg")',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
    <div className="col ast jc sectioncontent">
      <a id="cta" key="anchor" href="." style={{ visibility: 'hidden', cursor: 'none' }}>
        CTA
      </a>
      <h2 className="wh100 tac tsbl10">Request early access</h2>
      <br />
      <div className="row jc ac wrap" style={{ minWidth: '250px' }}>
        {false && (
          <a
            href="https://www.facebook.com/groups/bagelmd"
            target="_blank"
            rel="noopener noreferrer"
            className="mt8 mr8">
            <span className="btn solid bsbl10">
              <Icon type="facebook" theme="filled" style={{ fontSize: '21px' }} /> Join the
              conversation
            </span>
          </a>
        )}
        <WaitlistInput noText />
        {false && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://discuss.bagel.md"
            className="mt8">
            <span className="btn solidwhite bsbl10">Contribute to the roadmap</span>
          </a>
        )}
      </div>
    </div>
  </div>
];
