import React from 'react';
import { Icon, Button } from 'antd';

import WaitlistInput from './WaitlistInput';
import * as sassVars from '../style/_common.scss';

const HeroSection = () => {
  return (
    <div
      className="section fullpage bl70 row jc ac wrap"
      style={
        { backgroundColor: 'white', padding: '0px', padding: 'calc(2vw + 10px)' }
        // backgroundImage:  "url('/img/bagel_cropped.png')",
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // padding: '0px calc(53vw - 129px) 0px calc(3vw + 12px) ',
      }>
      {false && (
        <div className="col jc as fl1 sm-ac sm-center md-ac md-center">
          <div className="fl1" />
          <img
            src={'/img/bagel_small.jpg'}
            className="wide lg-hide xl-hide"
            style={{ width: 'calc(20vw + 50px)', marginTop: '75px' }}
            alt="Single Bagel"
          />

          <p className="tf bold fs36 blue">Introducing Bagel, the future of DPC software.</p>
          <br />

          <br />
        </div>
      )}
      <div className="fl2 col ac">
        <p className="tf bold fs36 blue tac" style={{ maxWidth: '500px' }}>
          Introducing Bagel, the future of DPC software.
        </p>
        <br />
        <br />
        <br />
        <p className="fs16 mb8 readable tac" style={{ maxWidth: '500px' }}>
          Bagel is still under development.
          <br />
          {false && `Join our Facebook group to stay updated.`}
          Join the waitlist to get notified when it's ready
        </p>

        {false && (
          <Button
            size="large"
            type="primary"
            icon="facebook"
            href="https://www.facebook.com/groups/bagelmd"
            target="_blank"
            rel="noopener noreferrer">
            Stay updated
          </Button>
        )}

        <br />

        {true && (
          <div className="wide row jc">
            {false && (
              <p style={{ fontSize: '10pt', opacity: 0.8 }}>
                join the waitlist to get notified when it's ready
              </p>
            )}
            <WaitlistInput noText={true} />
          </div>
        )}
      </div>
      {false && (
        <div
          className="col je ae sm-hide md-hide"
          style={
            {
              height: '100%',
              width: 'calc(50vw - 100px)',
              marginLeft: 'calc(3vw - 10px)',
              minWidth: '150px',
              maxWidth: '500px'
            } // start of div containing photo of bagel
          }>
          <img
            src={'/img/bagel_pile_cropped.jpg'}
            style={{ maxHeight: '476px', maxWidth: '100%' }}
            alt="Pile of bagels"
          />
        </div>
      )}

      <div
        className="row jc ac ts8 sm-hide"
        style={{ position: 'absolute', bottom: '9px', left: 'calc(50vw - 50px)', width: '100px' }}>
        <Icon
          type="down-circle"
          style={{ fontSize: '36px', color: sassVars.blueColor, opacity: 0.3 }}
          size="large"
        />
      </div>
    </div>
  );
};

export default HeroSection;
