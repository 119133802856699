import React from 'react';
import PersonRow from '../components/PersonRow';

export default () => (
  <div className="section" key="section">
    <a id="team" key="anchor" href="." style={{ visibility: 'hidden', cursor: 'none' }}>
      Team
    </a>
    <div className="col ac jc sectioncontent">
      <img src="/img/bagel_row.jpg" style={{ width: '200px' }} alt="Pile of bagels" />
      <h2>Meet the Team</h2>
      <br />
      <PersonRow name="Colin McDonnell" title="Founder of Bagel" photo="/img/colin2.jpg">
        Colin is an MIT-trained software engineer, a proud DPC patient, and the founder of Bagel.
        He's been developing resources and tools for the direct primary care community for two years
        as the creator of DPC Spot (<a href="https://dpcspot.com">dpcspot.com</a>), the author of
        the{' '}
        <a target="_blank" rel="noopener noreferrer" href="/todo/">
          DPC Startup Checklist
        </a>
        , and the CTO of <a href="https://dpcfrontier.com">DPC Frontier</a>.
      </PersonRow>
      <PersonRow name="Kenny Friedman" title="Head of Design" photo="/img/kenny2.jpg">
        Kenny is an MIT alum, experienced software engineer, and interface designer. Before joining
        Bagel, Kenny worked as a researcher at the MIT Human-Computer Interaction Group and the
        UI/UX Prototyping team of augmented reality company Magic Leap.
      </PersonRow>
      <PersonRow name="Phil Eskew, JD, DO, MBA" title="Advisor" photo="/img/phil.jpg">
        Dr. Eskew is the founder of DPC Frontier. He continues to practice broad scope family
        medicine, mostly in the correctional medicine setting in Wyoming. He is also the VP of
        Clinical Development and General Counsel at ProactiveMD.
      </PersonRow>
      <PersonRow name="Allison Edwards, MD" title="Advisor" photo="/img/allison.png">
        Dr. Edwards is the founder of Kansas City Direct Primary Care, a co-founder of the DPC
        Alliance, and blogger for the American Academy of Family Physicians. She is steadfastly
        focused on improving the US healthcare system through her work in academics, promoting broad
        scope of practice in primary care, and consulting with the health-tech sector as they work
        to serve the direct-pay marketplace.
      </PersonRow>
    </div>
  </div>
);
