import React from 'react';
import { Icon } from 'antd';

const FeaturesSection = () => {
  return (
    <div className="section">
      <a id="features" key="anchor" href="." style={{ visibility: 'hidden', cursor: 'none' }}>
        Pricing
      </a>
      <div className="wide col ac sectioncontent">
        <h2 className="mb32">Features</h2>
        <div className="row jc as wrap featurelist">
          <div className="featureitem">
            <Icon type="reconciliation" />
            <p>charting</p>
            <p>
              We have a new approach to charting that makes the easy things easy and the hard things
              possible. We use "slash commands" to put the full power of the EHR at your fingertips
              without cluttering the interface.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="mobile" />
            <p>mobile-friendly interface</p>
            <p>
              Our native iOS and Android apps are under development, but in the meantime our
              web-based dashboard is mobile-responsive and looks gorgeous on phones.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="credit-card" />
            <p>billing</p>
            <p>
              Automated credit card and ACH billing, age-based pricing, family plans, company
              discounts. The works.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="calendar" />
            <p>apppointments</p>
            <p>
              Create one-time and recurring events, add them to multiple users' calendars with a
              click, andspecify the required Resource so you never double book rooms or machines.
              Plus, you can sync all events to your Google calendar instantly.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="carry-out" />
            <p>self-scheduling</p>
            <p>
              Allow patients to request appointments or book directly via our embeddable scheduling
              widget. Customize your available hours, appointment type, appointment duration, and
              more.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="phone" />
            <p>patient communication</p>
            <p>
              Create a second phone number for your practice, or for each provider in your practice.
              Route calls and texts to different phones based on your schedule or the time of day.
              Respond to patients immediately via SMS.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="medicine-box" />
            <p>e-prescribe</p>
            <p>
              Seamlessly sync a patient's medication history into Bagel via our Surescripts
              integration, and create new prescriptions at almost any pharmacy in the country.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="experiment" />
            <p>ordering</p>
            <p>
              Order labs/pathology and receive results via our integrated e-fax system. We're
              working with a partner to integrate bi-directionally with every lab in the country, so
              you never have to hand-enter lab values again (coming Sept 2019).
            </p>
          </div>
          <div className="featureitem">
            <Icon type="check-circle" />
            <p>tasks</p>
            <p>
              Create tasks, assign one or more users, notify assignees via email or text. Resolve
              tasks with a click or snooze them until later.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="form" />
            <p>form builder</p>
            <p>
              Create custom forms from our built-in set of fields, or create your own trackable
              metrics. All form submissions drop straight into the patient's chart, no data entry
              needed.
            </p>
          </div>
          <div className="featureitem">
            <Icon type="barcode" />
            <p>inventory management</p>
            <p>
              Track your pharmacy inventory and any other durable goods and equipment. Our smart
              system recognizes when two NDC codes correspond to the same drug.
            </p>
          </div>
          {false && (
            <div className="featureitem">
              <Icon type="XXX" />
              <p>e-fax</p>
              <p>XXX</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
