import React from 'react';

import Head from '../components/Head';

import StandardPage from '../components/StandardPage';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import PricingSection from '../components/PricingSection';
import LetterSection from '../components/LetterSection';
import TeamSection from '../components/TeamSection';
import CTASection from '../components/CTASection';

import '../style/_common.scss';

class Home extends React.Component {
  render() {
    // const { email } = this.state;
    return (
      <StandardPage>
        <Head
          title="All-in-One Software for Direct Primary Care"
          description="Bagel is an all-in-one software platform for direct
          primary care practices. If you're looking for a physician-friendly EHR, a powerful billing 
          platform, a flexible scheduling tool, an e-prescribing system, or all of the above, then Bagel is for you."
          url=""
          image="/og_image.jpg"
        />

        <HeroSection />
        {false && <LetterSection />}
        <FeaturesSection />
        <PricingSection />
        <TeamSection />
        <CTASection />
      </StandardPage>
    );
  }
}

export default Home;
